import store from '@/vue-app/store';

export default class LoginViewModel {
  login_page_route = process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL;

  constructor() {
    this.showGoToSiteOnHeader();
  }

  private showGoToSiteOnHeader() {
    store.dispatch('layout/toggleGoToSiteOnHeader', true);
  }
}
