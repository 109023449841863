


























import { Vue, Component } from 'vue-property-decorator';
import LoginViewModel from '@/vue-app/view-models/pages/login-view-model';

@Component({
  name: 'Login',
})
export default class Login extends Vue {
  loginViewModel = Vue.observable(new LoginViewModel());
}
